<template>
  <div>
    <div class="pa-3">
      <v-card
        class="mx-auto pa-3 ems-opacity-25-bg frosted-glass"
        elevation="0"
      >
        <v-form ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="auto">
              <v-select
                dense
                v-model="query.type"
                :items="typeOptions"
                background-color="white"
                elevation="0"
                item-text="text"
                item-value="value"
                solo
                flat
                label="報表類型"
                required
                @input="selectQueryType"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <reportDateRangeSelect
                v-model="selectDates"
                :type="query.type"
                solo
                flat
                dense
              />
            </v-col>
            <v-col cols="12" class="pa-0"> </v-col>
            <v-col cols="auto">
              <v-select
                dense
                v-model="query.meterId"
                :items="filteredMeters"
                background-color="white"
                elevation="0"
                item-text="name"
                item-value="id"
                solo
                flat
                :rules="[(v) => !!v || '此欄位為必填']"
              >
                <template v-slot:label>
                  請選擇電表<span class="error--text">*</span>
                </template>
                <!-- <template v-slot:item="{ item }">
                  <div class="py-1">
                    <div>{{ item.name }}</div>
                    <v-sheet
                      width="fit-content"
                      class="text-caption px-1"
                      color="ems-light-grey"
                    >
                      {{ item.serialNumber || 'null' }} |
                      {{ item.modbusId || 'null' }}
                    </v-sheet>
                  </div>
                </template> -->
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="mt-2 justify-sm-end">
            <v-col cols="auto">
              <v-btn outlined class="mr-4 px-8" @click="reset" color="accent">
                重設
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                v-if="query.meterId !== 'allClassroom'"
                :loading="loadingData"
                elevation="0"
                class="mr-4 px-8 accent--text"
                color="ems-aquamarine"
                @click="submit"
              >
                產生報表
              </v-btn>
            </v-col>
            <v-col cols="auto" v-permission="['SCH_ADMIN']">
              <downloadBtn
                v-if="query.meterId !== 'allClassroom'"
                @exportFile="exportFile"
              />
              <v-btn
                v-else
                color="primary"
                elevation="0"
                class="mr-4 px-8"
                @click="exportClassroomReport"
              >
                匯出報表
              </v-btn>
            </v-col>
            <v-col cols="auto" v-permission="['SCH_ADMIN']">
              <v-btn
                v-if="query.meterId !== 'allClassroom'"
                :loading="loadingUpload"
                elevation="0"
                class="mr-4 px-8"
                color="ems-powder-blue"
                @click="uploadData"
              >
                上傳報表
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-card
        class="mx-auto pa-3 mt-3 ems-opacity-50-bg frosted-glass"
        elevation="0"
        v-if="reportData"
      >
        <div class="pb-8" v-if="!loadingData">
          <div class="accent--text mr-4">
            最大需量: {{ reportData.maxDemand }} kW
          </div>
          <div class="accent--text mr-4">
            {{ queryMeterType === 'SOLARMETER' ? '總發電量' : '總用電量' }}:
            {{ reportData.total }} kWh
          </div>
        </div>
        <div class="chart-wrap mt-3" v-if="!loadingData">
          <Echart
            :no-data="!reportData"
            noDataText="無資料"
            :data="chartDatasets"
          />
        </div>
        <div class="mt-4">
          <v-data-table
            :loading="loadingData"
            :headers="headers"
            :items="reportData.energy || []"
            item-class="accent--text"
            :items-per-page="10"
            loading-text="Loading..."
            no-data-text="查無資料"
            :footer-props="{
              'items-per-page-text': '每頁'
            }"
          ></v-data-table>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Meter from '@/api/ems/Meter'
import Echart from '@/components/Echart'
import reportDateRangeSelect from '@/components/reportDateRangeSelect'
import makeChart from '@/vendor/chart'
import downloadBtn from './downloadBtn'

export default {
  components: {
    Echart,
    reportDateRangeSelect,
    downloadBtn
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectDates: [],
      typeOptions: [
        { value: 'daily', text: '日報' },
        { value: 'monthly', text: '月報' },
        { value: 'yearly', text: '年報' }
      ],
      meters: [],
      query: {
        from: null,
        to: null,
        type: 'daily',
        meterId: null,
        page: 1
      },
      loadingData: false,
      loadingUpload: false,
      reportData: null
    }
  },
  watch: {
    query: {
      deep: true,
      handler() {
        if (this.reportData) {
          this.reportData = null
        }
      }
    },
    selectDates(val) {
      this.query.from = val[0]
      this.query.to = val[1] || val[0]
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData'
    }),
    id() {
      return this.pageData.id || this.tagId
    },
    formValid() {
      return (
        this.query.type &&
        this.query.from &&
        this.query.to &&
        this.query.meterId
      )
    },
    headers() {
      // 時間
      const fields = [
        {
          value: 'timestamp',
          text: '時間',
          class: 'ems-bluegrey--text font-weignt-bold subtitle-1'
        },
        {
          value: 'value',
          text: this.queryMeterType === 'SOLARMETER' ? '發電量' : '用電量',
          class: 'ems-bluegrey--text font-weignt-bold subtitle-1'
        }
      ]
      return fields
    },
    chartDatasets() {
      if (!this.reportData || !this.reportData.energy) {
        return null
      }
      return {
        ...makeChart.data(this.query.type),
        dataset: {
          dimensions: ['timestamp', 'value'],
          source: this.reportData.energy
        },
        series: [
          {
            name: this.queryMeterType === 'SOLARMETER' ? '發電量' : '用電量',
            type: 'line'
          }
        ]
      }
    },
    queryMeterType() {
      let findItem = this.filteredMeters.find(
        ({ id }) => id === this.query.meterId
      )
      return findItem ? findItem.type : undefined
    },
    filteredMeters() {
      return this.query.type === 'yearly'
        ? this.meters
        : this.meters.concat([{ name: '所有教室電表', id: 'allClassroom' }])
    }
  },
  async mounted() {
    this.getSchoolMeters()
  },
  methods: {
    ...mapActions({
      showMsg: 'snackbar/show',
      closeMsg: 'snackbar/close'
    }),
    reset() {
      this.closeMsg()
      this.$refs.form.resetValidation()
      this.query = {
        from: null,
        to: null,
        type: 'daily',
        page: 1
      }
      this.selectDates = []
    },
    async submit() {
      this.closeMsg()
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.loadingData = true
      if (this.reportData) {
        this.reportData.energy = null
      }
      try {
        const { data } = await Meter.statistic(
          this.query.meterId,
          this.query.type,
          {
            isSchoolMeter: true,
            date: this.query.from
          }
        )
        this.reportData = data
      } catch (error) {
        console.error(error)
        this.reportData = null
        const msg = error.response.data.message || '發生錯誤，請稍後再試'
        this.showMsg({
          message: `${error.response.status + ' ' || ''} ${msg}`,
          color: 'error',
          closable: true,
          time: 4000
        })
      } finally {
        this.loadingData = false
      }
    },
    async uploadData() {
      this.closeMsg()
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.loadingUpload = true
      try {
        await Meter.schoolStatisticExport(
          this.query.meterId,
          this.query.type,
          'csv',
          {
            isSchoolMeter: true,
            date: this.query.from,
            responseType: 'uploaded-status'
          }
        )
        this.showMsg({
          message: `上傳成功`,
          closable: true,
          time: 4000
        })
      } catch (error) {
        this.showMsg({
          message: `${error.response.status + ' ' || ''}發生錯誤，請稍後再試`,
          color: 'error',
          closable: true,
          time: 4000
        })
      } finally {
        this.loadingUpload = false
      }
    },
    async getSchoolMeters() {
      this.meters = []
      try {
        const { data } = await Meter.getSchoolMeters(this.tagId)
        this.meters = data.sort(function (a, b) {
          return a.seq - b.seq
        })
      } catch (error) {
        console.error(error)
      }
    },
    async exportClassroomReport() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      let executeFunction =
        this.query.type === 'daily'
          ? Meter.exportDailyClassroomReport(this.tagId, {
              date: this.query.from
            })
          : Meter.exportMonthlyClassroomReport(this.tagId, {
              date: this.query.from
            })
      try {
        const response = await executeFunction
        this.downloadFile(response, 'csv')
      } catch (error) {
        console.error(error)
      }
    },
    async exportFile(type) {
      const validate = this.$refs.form.validate()
      if (!validate) return
      try {
        const response = await Meter.schoolStatisticExport(
          this.query.meterId,
          this.query.type,
          type,
          {
            isSchoolMeter: true,
            date: this.query.from
          }
        )
        this.downloadFile(response, type)
      } catch (error) {
        console.error(error)
      }
    },
    downloadFile(response, fileType) {
      if (fileType === 'csv') {
        const url = window.URL.createObjectURL(
          new Blob([(response.data ? '\ufeff' : '') + response.data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename(fileType)
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } else if (fileType === 'json') {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(response.data))
        var downloadAnchorNode = document.createElement('a')
        downloadAnchorNode.setAttribute('href', dataStr)
        let fileName = this.getResponseFilename(fileType)
        downloadAnchorNode.setAttribute('download', fileName)
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
      }
    },
    getResponseFilename(fileType) {
      let meterName = this.filteredMeters.find(
        (meter) => meter.id === this.query.meterId
      ).name
      let reportType = this.typeOptions.find(
        (type) => type.value === this.query.type
      ).text
      let date = ''
      if (this.query.type === 'monthly')
        date = `${this.query.from.split('-')[0]}-${
          this.query.from.split('-')[1]
        }`
      else if (this.query.type === 'yearly')
        date = `${this.query.from.split('-')[0]}`
      else date = this.query.from
      return `${this.pageData.displayName}_${meterName}_${reportType}_${date}.${fileType}`
    },
    selectQueryType(val) {
      if (val === 'yearly' && this.query.meterId === 'allClassroom')
        this.query.meterId = null
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrap {
  height: 390px;
  width: 100%;
}
*::v-deep {
  .v-data-table {
    background: transparent;
  }
}
</style>
